(function ($) {
  $(function () {

    /*
     * Navbar fixed
     */
    $(window).on('scroll', function () {
      if ($(this).scrollTop() > 1) {
        $('.site-header').addClass('navbar-fixed-top');
      } else {
        $('.site-header').removeClass('navbar-fixed-top');
      }
    });

    /*
     * Toggle burger menu on mobile
     */
    $('.menu-btn-burger').on('click', function () {
      $('#nav-icon').toggleClass('open-custom-menu');
      $('body').toggleClass('fixedPosition');
      $(".mobile-menu").toggleClass('sliding-open');
    });

    /*
     * Owl carousel
     */
    $('.owl-phone').owlCarousel({
      margin: 0,
      items: 1,
      loop: true,
      autoplay: true,
      autoplayTimeout: 8000,
      nav: false,
      mouseDrag: false,
      touchDrag: false,
      dots: false,
      autoplayHoverPause: false,
      // animateOut: 'fadeOut',
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          center: true,
          margin: 0
        },
        760: {
          items: 3,
          center: true,
          margin: 0
        },
        1000: {
          items: 1
        }

      }
    });

    $('.owl-home-text').owlCarousel({
      margin: 0,
      items: 1,
      loop: true,
      autoplay: true,
      autoplayHoverPause: false,
      autoplayTimeout: 8000,
      nav: false,
      mouseDrag: false,
      touchDrag: false,
      dots: false,
      // animateOut: 'fadeOut',
      responsiveClass: true,
    });

    $('.app-box .description').eq(0).addClass('active');
    $('.app-box').eq(0).addClass('active');
    $('.app-box').on('click', function () {
      $(this).find('.description').toggleClass('active');
      $(this).toggleClass('active');
    });


    $('[data-toggle="tooltip"]').tooltip();

    $('.collapse').on('shown.bs.collapse', function(e) {
      var $card = $(this).closest('.card');
      $('html,body').animate({
        scrollTop: $card.offset().top - 150
      }, 500);  
    });

    /*
     * Lightbox gallery
     */
    $('.gallery-images').lightGallery({
      selector: '.gallery-item',
      share: true,
      download: true,
      rotate: false,
      showThumbByDefault: true,
      loop: false,
      slideEndAnimatoin: false,
      thumbnail: true,
      allowMediaOverlap: true,
      toggleThumb: true,
      youtubePlayerParams: {
        loadYoutubeThumbnail: true,
        youtubeThumbSize: 'default',
        modestbranding: 1,
        showinfo: 0,
        rel: 0
      }
    });

    var player;
    var videoModules;
    $('.intro-video').on('click', function () {
      $('#intro-modal').modal('show');

      var tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      document.body.appendChild(tag);
      $(this.parentNode).addClass("playing");
    });



    window.onYouTubeIframeAPIReady = function () {
      videoModules = document.querySelectorAll('.video');
      // for Internet Explorer 11 and below, convert array-like NodeList to an actual Array.
      videoModules = Array.prototype.slice.call(videoModules);
      videoModules.forEach(initializeVideoModule);

    }



    function initializeVideoModule(videoModule) {
      player = new YT.Player(videoModule.querySelector('.video-placeholder'), {
        videoId: videoModule.dataset.videoId,
        events: {
          'onReady': onPlayerReady,
          onStateChange: function (event) {
            if (event.data === 0) {
              // player.destroy();
              $('#intro-modal').modal('hide');
              videoModule.querySelector('.video-layer').innerHTML = (
                '<div class="video-placeholder"></div>'
              );
              initializeVideoModuleNew(videoModule);
            } 
          },
        }
      });

    }


    function initializeVideoModuleNew(videoModule) {
      player = new YT.Player(videoModule.querySelector('.video-placeholder'), {
        videoId: videoModule.dataset.videoId,
        events: {
          onStateChange: function (event) {
            var isEnded = event.data === YT.PlayerState.ENDED;
            // 'playing' css class controls fading the video and preivew images in/out.
            // Internet Explorer 11 and below do not support a second argument to `toggle`
            // videoModule.classList.toggle('playing', !isEnded);
            videoModule.classList[isEnded ? 'remove' : 'add']('playing');
            // if the video is done playing, remove it and re-initialize
            if (isEnded) {
              player.destroy();
              $('#intro-modal').modal('hide');
              videoModule.querySelector('.video-layer').innerHTML = (
                '<div class="video-placeholder"></div>'
              );
              initializeVideoModuleNew(videoModule);
            }
          }
        }
      });
    }



    $('#intro-modal').on('hidden.bs.modal', function (event) {
      console.log('close');
      player.pauseVideo();
    })


    function onPlayerReady(event) {
      event.target.mute();
      event.target.playVideo();
    }


    

  });
})(jQuery);